<template>
  <b-row>
    <b-col md="2">
      <filters-card
          :start.sync="start"
          :end.sync="end"
      />
    </b-col>

    <b-col md="10">
      <expenses-types-card
          :start="start"
          :end="end"
      />

      <articles-card
          :start="start"
          :end="end"
      />

    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import moment from 'moment'
import FiltersCard from './components/_filters'
import useAPI from '../../../utils/useAPI'
import ArticlesCard from '../../../components/indicators/articles/ArticlesCard'
import ExpensesTypesCard from '../../../components/indicators/expenses/ExpensesTypesCard'

export default {
  components: {
    FiltersCard,
    ArticlesCard,
    ExpensesTypesCard
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentYear = parseInt(new Date().getFullYear())
    const start = ref(moment(currentYear + '-07-01').format('YYYY-MM-DD'))
    const end = ref(moment((currentYear + 1) + '-06-30').format('YYYY-MM-DD'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const {
    //   fetchUsers,
    //   fetchOrders,
    //   fetchIncomingInvoices,
    //   fetchEvents,
    //   fetchSalaries,
    //   fetchSocialSecurityContributions
    // } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // fetchUsers()
    // fetchOrders()
    // fetchIncomingInvoices()
    // fetchEvents()
    // fetchSalaries()
    // fetchSocialSecurityContributions()

    return {
      // Components

      // Data
      start,
      end,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>